import './FinanceOpenCard.css';
import React, { useEffect, useState, useRef } from 'react';
import FinanceCardOpenView from './FinanceCardOpenView';
import axios from "axios";
import { format, addMonths, parseISO, differenceInMonths } from 'date-fns';
import ImportFinancesWindow from './ImportFinancesWindow';
import ToggleSliderSwitch from './ToggleSliderSwitch';
import ErrorMessage from '../../../../../GenericComponents/ErrorMessage';
import { useFinancialContext } from '../../../../../../FinancialContext';
import HeaderOpenCardView from './HeaderOpenCardView';
import Dropbox from './Dropbox';
import { getBankLogo, getCategorieIcon } from '../../../../../../staticData'
import useWindowWidth from '../../../../../../useWindowWidth';
import OverlaySelection from '../../../../DashboardSideBar/OverlaySelection';
import useViewportHeight from '../../../../../../useViewportHeight';

function FinanceOpenCard({ type, financeData, createFinance, closeFullView, openFormsCreateNewFinance, setOpenFormsCreateNewFinance }) {
    useViewportHeight(); 

    const { yearSelected, monthNumber, monthName, categoriesData, renderFinanceHandler, updateDeleteCreateSpecificFinance,
        updateDeleteCreateFinance, axiosURL, setCreateNewGroup, setIncomeData, setSpendData, creditCardData,
    } = useFinancialContext();
    const isMobile = useWindowWidth();

    const [financialData, setFinancialData] = useState(financeData ? financeData : null);

    const [currentDate, setCurrentDate] = useState(format(new Date(), `yyyy-MM-dd`));
    const [daysOfMonth, setDaysOfMonth] = useState(null);
    const [financeTotal, setFinanceTotal] = useState(null);

    const [clickedFinance, setClickedFinance] = useState(null);

    const [error_message, setError_message] = useState('');

    const [detailFinances, setDetailFinances] = useState(false); //Detalhar o formulário
    const [calendarEndRecurrence, setCalendarEndRecurrence] = useState(false);

    const [alertUpdateFinance, setAlertUpdateFinance] = useState(false)

    const [numbersToEndRecurrence, setNumbersToEndRecurrence] = useState(0);
    const [activeRecurrenceSelectedFinance, setActiveRecurrenceSelectedFinance] = useState(false);
    const [deleteFinanceWindow, setDeleteFinanceWindow] = useState(false);

    const [closeFilterWindows, setCloseFilterWindows] = useState(false);
    const [importOptions, setImportOptions] = useState(false);
    const [activateImportFinances, setActivateImportFinances] = useState(false);

    const [selectedCreditCard, setSelectedCreditCard] = useState()
    const [selectedCategorie, setSelectedCategorie] = useState()

    const [inputs, setInputs] = useState({
        data: `${yearSelected}-${monthNumber}-${currentDate.toString().slice(-2)}`,
        nome: '',
        valor: 0,
        categoria: null,
        recorrente: false,
        fimRecorrencia: null,
        observacoes: null,
        dataFimFinanca: null,
        pagaOuRecebida: false,
        specific: false
    });

    const mobileStyleDefaultDisplayImg = {
        width: '25px',
        height: '25px',
        marginLeft: '0%',
        display: 'flex'
    }
    const mobileStyleDefaultDisplayP = {
        fontSize: '3.8vw',
        display: 'flex',
        maxWidth: '70vw',
        width: '70%',
        textIndent: '0%',
        textOverflow: 'ellipsis'
    }
    const mobileStyleCategoriesList = {
        height: '25px',
        width: '25px',
        display: 'flex'
    }
    const mobileStyleCategoriesIconList = {
        minHeight: '15px',
        minWidth: '15px',
        display: 'flex'
    }
    const mobileStyleCreditCardList = {
        width: '28px',
        maxHeight: '28px',
        display: 'flex',
        marginLeft: '0%'
    }

    const handleInputName = (name) => {
        setInputs((prevInputs) => ({
            ...prevInputs,
            nome: name.target.value
        }));
    }
    const handleInputValue = (value) => {
        let rawValue = value.replace(/\D/g, '');

        const valueAsNumber = Number(rawValue) / 100;

        const valueFormatted = valueAsNumber.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        setInputs((prevInputs) => ({
            ...prevInputs,
            valor: valueFormatted
        }));

    }
    const handleInputDate = (e) => {
        const day = e.target.value.replace(/\D/g, '');
        const lastDayInMonth = getDaysOfMonth().slice(-1)[0];
        if (day <= lastDayInMonth && day !== 0) {
            const formattedYear = String(yearSelected);
            const formattedMonth = adicionarZeroEsquerdaMes(monthNumber);
            const formattedDay = adicionarZeroEsquerdaMes(day).toString().slice(-2);

            const formattedDate = `${formattedYear}-${formattedMonth}-${formattedDay}`;

            setInputs((prevInputs) => ({
                ...prevInputs,
                data: formattedDate
            }));
        } else {
            setError_message("A data informada é inválida.");
        }
    }
    const handleSwitchRecurrence = (recurrence) => {
        if (!recurrence && !clickedFinance) {
            setNumbersToEndRecurrence(0);
            setInputs((prevInputs) => ({
                ...prevInputs,
                fimRecorrencia: null
            }));
            setActiveRecurrenceSelectedFinance(false);
        }

        setInputs((prevInputs) => ({
            ...prevInputs,
            recorrente: recurrence
        }));
        setActiveRecurrenceSelectedFinance(true);
    };
    const handleInputEndRecurrence = (numbersOfMonths) => {
        const numberOfMonthsFormatted = numbersOfMonths.replace(/\D/g, '');

        //Colocar como zero o número, limpar o final da recorrência e tirar do input caso o usuário apague o que inseriu
        if (numberOfMonthsFormatted === null || numberOfMonthsFormatted === '' || numberOfMonthsFormatted < 1) {
            setInputs((prevInputs) => ({
                ...prevInputs,
                fimRecorrencia: null
            }));
            setNumbersToEndRecurrence(0);
            return;
        }

        setNumbersToEndRecurrence(parseInt(numberOfMonthsFormatted));
        const inicialDate = parseISO(`${yearSelected}-${adicionarZeroEsquerdaMes(monthNumber)}-01`)
        const finalRecurrence = format(addMonths(inicialDate, numberOfMonthsFormatted - 1), 'yyyy-MM-dd');

        setInputs((prevInputs) => ({
            ...prevInputs,
            fimRecorrencia: finalRecurrence
        }));
    }
    const handleInputObservations = (observation) => {
        setInputs((prevInputs) => ({
            ...prevInputs,
            observacoes: observation.target.value
        }));
    }

    const getDateSelected = () => {
        return new Date(`${yearSelected}-${adicionarZeroEsquerdaMes(monthNumber)}-${inputs.data.toString().slice(-2)}`);
    }
    const adicionarZeroEsquerdaMes = (mes) => {
        return mes.padStart(2, '0');
    }


    //Função de requisição para o backend de deletar finanças
    const fetchDeleteFinance = async () => {
        try {
            const token = localStorage.getItem("SnnAcss");
            const response = await axios.delete(`${axiosURL}/${type}/${parseInt(clickedFinance.id)}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 204) {
                updateDeleteCreateFinance("delete", clickedFinance.id, type);
                clearForms();
            }
        } catch (error) {
            console.log(error)
        }
    }
    //Função de requisição para o backend de alteração de finanças
    const fetchUpdateFinance = async (FinalDateAux, finance) => {
        try {
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            let updatedFinance;

            if (!finance) {
                const creditCard = selectedCreditCard?.id;

                updatedFinance = finance ? finance : {
                    id: clickedFinance.id,
                    data: inputs.data,
                    nome: inputs.nome,
                    valor: parseFloat(inputs.valor.replace(/\./g, '').replace(',', '.')),
                    categoria: inputs.categoria?.id,
                    recorrente: inputs.recorrente,
                    fimRecorrencia: inputs.fimRecorrencia ? new Date(inputs.fimRecorrencia) : null,
                    observacoes: inputs.observacoes ? inputs.observacoes : '',
                    dataFimFinanca: FinalDateAux ? FinalDateAux : new Date(clickedFinance.dataFimFinanca),
                    specificFinances: clickedFinance.specificFinances,
                    pagaOuRecebida: clickedFinance.pagaOuRecebida,
                    specificId: clickedFinance.specific ? clickedFinance.idSpecificFinance : null,
                    cartaoCredito: creditCard
                }
            } else {//Alteração apenas de finança paga
                updatedFinance = {
                    id: finance.id,
                    data: finance.data,
                    nome: finance.nome,
                    valor: finance.valor,
                    categoria: finance.categoria?.id,
                    recorrente: finance.recorrente,
                    fimRecorrencia: finance.fimRecorrencia,
                    observacoes: finance.observacoes,
                    dataFimFinanca: finance.dataFimFinanca,
                    specificFinances: finance.specificFinances,
                    pagaOuRecebida: finance.pagaOuRecebida,
                    cartaoCredito: finance.creditCard ? finance.creditCard.id : null
                }
            }

            const response = await axios.put(`${axiosURL}/${type}`, updatedFinance, config);

            if (response.status === 200) {
                if (!finance) clearForms();
                updateDeleteCreateFinance("update", response.data, type);
            }
        } catch (error) {
            console.log(error)
        }
    }
    //Função para dar um fetch e criar uma nova finança, seja receita ou gasto
    const fetchNewFinance = async () => {

        if (
            inputs.nome === '' ||
            inputs.valor <= 0 ||
            inputs.data === null || inputs.data === ''
        ) {
            setError_message("A data, nome e valor são obrigatórios!");
            return;
        }

        if (inputs.data.toString().slice(-2) == '00') {
            setError_message("A data informada é inválida.");
            return;
        }

        try {
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const financeDate = getDateSelected();
            const newFinance = {
                data: financeDate,
                nome: inputs.nome,
                valor: parseFloat(inputs.valor.replace(/\./g, '').replace(',', '.')),
                categoria: inputs.categoria?.id,
                recorrente: inputs.recorrente,
                fimRecorrencia: inputs.fimRecorrencia ? new Date(inputs.fimRecorrencia) : null,
                observacoes: inputs.observacoes,
                cartaoCredito: selectedCreditCard ? selectedCreditCard.id : null
            }



            const response = await axios.post(`${axiosURL}/${type}`, newFinance, config);

            if (response.status === 201) {
                clearForms();
                updateDeleteCreateFinance("create", response.data, type);
            }
        } catch (error) {
            setError_message("Ocorreu um erro ao criar a nova finança.");
            console.error(error);
        }
    };
    //Função para atualizar a finança a partir do mês atual - Atualiza o mês atual e os futuros, os anteriores permanecem o mesmo
    const fetchUpdateNowOnFinance = async () => {
        const financeDate = getDateSelected();
        const creditCard = selectedCreditCard?.id;

        try {
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };



            const newSpecificFinance = {
                idFinancaReferenciada: clickedFinance.id,
                mesReferencia: financeDate,
                nomeEspecifico: inputs.nome,
                valorEspecifico: parseFloat(inputs.valor.replace(/\./g, '').replace(',', '.')),
                categoriaEspecificaId: inputs.categoria?.id,
                observacaoEspecifica: inputs.observacoes,
                ativaNoMes: true,
                pagaOuRecebida: clickedFinance.pagaOuRecebida,
                dataFinalEspecifica: "",
                repetirFinanca: true,
                specific: clickedFinance.specific,
                specificId: clickedFinance.specific ? clickedFinance.idSpecificFinance : null,
                cartaoCreditoId: creditCard
            }

            const response = await axios.post(`${axiosURL}/${type}/updateNowOn`, newSpecificFinance, config);
            if (response.status === 201) {
                clearForms();
                updateDeleteCreateFinance("update", response.data, type);
            }

        } catch (error) {
            console.log(error)
        }
    }
    //Função para criar uma finança - "ativa" é a variável que define se é para desativar ou não a finança específica
    const fetchCreateSpecificFinance = async (ativa, finance) => {

        //Verificando se a finança para ser atualizada é específica, está se repetindo, e é a finança responsável pela repetição - 
        //É necessário passar a repetição para a do mês seguinte e criar uma nova específica neste mês
        if (clickedFinance
            && clickedFinance.specific
            && (
                clickedFinance.repetirFinancaEspecifica &&
                format(new Date(yearSelected, parseInt(monthNumber) - 1, clickedFinance.data.toString().slice(-2)), 'MM/yyyy') === format(parseISO(clickedFinance.data), `MM/yyyy`)
            )) {
            fetchUpdateRepetedSpecificFinance(ativa);
            return;
        }

        //Verficando se já há uma finança específica neste mês
        if (clickedFinance
            && clickedFinance.specific
            && !clickedFinance.repetirFinancaEspecifica) {
            fetchUpdateSpecificFinance(ativa);
            return;
        }

        const token = localStorage.getItem("SnnAcss");
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        const financeDate = getDateSelected();
        let newSpecificFinance;

        if (!finance) {//alteração de apenas check de finança
            const creditCard = selectedCreditCard?.id;

            newSpecificFinance = {
                idFinancaReferenciada: clickedFinance.id,
                mesReferencia: financeDate,
                nomeEspecifico: inputs.nome,
                valorEspecifico: parseFloat(inputs.valor.replace(/\./g, '').replace(',', '.')),
                categoriaEspecificaId: inputs.categoria?.id,
                observacaoEspecifica: inputs.observacoes,
                ativaNoMes: ativa,
                pagaOuRecebida: false,
                cartaoCreditoId: creditCard
            }
        } else {
            const date = (finance.data instanceof Date) ? finance.data : parseISO(finance.data);
            const financeDate = new Date(`${yearSelected}-${adicionarZeroEsquerdaMes(monthNumber)}-${format(date, 'dd')}`)
            newSpecificFinance = {
                idFinancaReferenciada: finance.id,
                pagaOuRecebida: finance.pagaOuRecebida,
                mesReferencia: financeDate,
                nomeEspecifico: finance.nome,
                valorEspecifico: finance.valor,
                categoriaEspecificaId: finance.categoria?.id,
                observacaoEspecifica: finance.observacoes,
                ativaNoMes: true,
                cartaoCreditoId: finance.creditCard?.id
            }
        }

        const response = await axios.post(`${axiosURL}/${type}/specificFinance`, newSpecificFinance, config);
        if (response.status === 201) {
            if (!finance) clearForms();
            updateDeleteCreateSpecificFinance("create", response.data, type)
        }
    }
    //Função para atualizar uma finança específica
    const fetchUpdateSpecificFinance = async (ativa, finance) => {

        const token = localStorage.getItem("SnnAcss");
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        let updatedSpecificFinance;

        if (!finance) {//alteração de apenas check de finança
            const creditCard = selectedCreditCard?.id;
            const financeDate = getDateSelected();

            updatedSpecificFinance = {
                id: clickedFinance.idSpecificFinance,
                idFinancaReferenciada: clickedFinance.id,
                dataEspecifica: financeDate,
                nomeFinancaEspecifica: inputs.nome,
                valorFinancaEspecifica: parseFloat(inputs.valor.replace(/\./g, '').replace(',', '.')),
                idCategoriaFinancaEspecifica: inputs.categoria?.id,
                observacaoEspecifica: inputs.observacoes,
                ativaNoMesEspecifica: ativa,
                pagoOuRecebidaEspecifica: clickedFinance.pagaOuRecebida,
                cartaoCreditoId: creditCard
            }
        } else {
            updatedSpecificFinance = {
                id: finance.idSpecificFinance,
                idFinancaReferenciada: finance.id,
                dataEspecifica: finance.data,
                nomeFinancaEspecifica: finance.nome,
                valorFinancaEspecifica: finance.valor,
                idCategoriaFinancaEspecifica: finance.categoria?.id,
                observacaoEspecifica: finance.observacoes,
                ativaNoMesEspecifica: true,
                pagoOuRecebidaEspecifica: finance.pagaOuRecebida,
                cartaoCreditoId: finance.creditCard ? finance.creditCard.id : null
            }
        }

        const response = await axios.put(`${axiosURL}/${type}/specificFinance`, updatedSpecificFinance, config);
        if (response.status === 200) {
            if (!finance) clearForms();
            updateDeleteCreateSpecificFinance("update", response.data, type)
        }
    }
    //Função para editar a finança específica que se repete - No caso ela cria uma nova específica para o mês que o usuário está vendo com as informações atuais, e cria uma nova específica para o mês seguinte, com as informações da finança
    const fetchUpdateRepetedSpecificFinance = async (ativa, finance) => {
        const token = localStorage.getItem("SnnAcss");
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };

        let updatedSpecificFinance;
        if (!finance) {
            const creditCard = selectedCreditCard?.id;
            const financeDate = getDateSelected();

            updatedSpecificFinance = {
                id: clickedFinance.idSpecificFinance,
                idFinancaReferenciada: clickedFinance.id,
                dataEspecifica: financeDate,
                nomeFinancaEspecifica: inputs.nome,
                valorFinancaEspecifica: parseFloat(inputs.valor.replace(/\./g, '').replace(',', '.')),
                idCategoriaFinancaEspecifica: inputs.categoria?.id,
                observacaoEspecifica: inputs.observacoes,
                ativaNoMesEspecifica: ativa,
                pagoOuRecebidaEspecifica: clickedFinance.pagaOuRecebida,
                cartaoCreditoId: creditCard
            }
        } else {
            updatedSpecificFinance = {
                id: finance.idSpecificFinance,
                idFinancaReferenciada: finance.id,
                dataEspecifica: finance.data,
                nomeFinancaEspecifica: finance.nome,
                valorFinancaEspecifica: finance.valor,
                idCategoriaFinancaEspecifica: finance.categoria?.id,
                observacaoEspecifica: finance.observacoes,
                ativaNoMesEspecifica: true,
                pagoOuRecebidaEspecifica: finance.pagaOuRecebida,
                cartaoCreditoId: finance.creditCard ? finance.creditCard.id : null
            }
        }

        const response = await axios.put(`${axiosURL}/${type}/specificFinance/updateRepetedSpecific`, updatedSpecificFinance, config);
        if (response.status === 200) {
            if (!finance) clearForms();
            updateDeleteCreateFinance("update", response.data, type);
        }
    }



    const handleClickPaidReceived = (finance) => {
        const { specific, recorrente, repetirFinancaEspecifica } = finance;
        if (!specific && !recorrente) {
            fetchUpdateFinance(null, finance);
        } else if (!specific && recorrente) {
            fetchCreateSpecificFinance(true, finance);
        } else if (specific && !repetirFinancaEspecifica) {
            fetchUpdateSpecificFinance(true, finance);
        } else if (specific && repetirFinancaEspecifica) {
            const dateSelected = new Date(yearSelected, monthNumber - 1, finance.data.toString().slice(-2))
            const dateSelectedFormatted = format(dateSelected, `yyyy/MM`);
            const dataFin = format(parseISO(finance.data), `yyyy/MM`)

            if (dateSelectedFormatted === dataFin) {
                fetchUpdateRepetedSpecificFinance(true, finance);
            } else {
                const newFinance = {
                    ...finance,
                    data: dateSelected
                }

                fetchCreateSpecificFinance(true, newFinance);
            }
        }
    };

    //Função para parar a propaganação. Usada para fechar janelas
    const stopPropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }
    //Função para fechar a janela do calendário do formulário
    const handleCloseAllWindows = (event) => {
        event.stopPropagation();
        setCalendarEndRecurrence(false)
        setDeleteFinanceWindow(false)
        setAlertUpdateFinance(false)
        setCloseFilterWindows(true);
        setImportOptions(false)
        setActivateImportFinances(false);
        setCloseFilterWindows(true);
    };

    //Função para criar uma array com todos os números que sejam equivalentes aos dias do mês que o usuário está vendo
    const getDaysOfMonth = () => {
        const MonthsWithThirtyOneDays = ["01", "03", "05", "07", "08", "10", "12"];
        const MonthsWithThirtyDays = ["04", "06", "09", "11"];
        const monthSelectedByUser = adicionarZeroEsquerdaMes(monthNumber);
        let days = [];

        if (MonthsWithThirtyOneDays.includes(monthSelectedByUser)) {
            days = Array.from({ length: 31 }, (_, index) => index + 1);
        } else if (MonthsWithThirtyDays.includes(monthSelectedByUser)) {
            days = Array.from({ length: 30 }, (_, index) => index + 1);
        } else if (monthSelectedByUser === "02") {
            const year = yearSelected;
            days = (year % 400 === 0) || ((year % 4 === 0) && (year % 100 !== 0)) // Verificação de bissexto
                ? Array.from({ length: 29 }, (_, index) => index + 1)
                : Array.from({ length: 28 }, (_, index) => index + 1);
        }

        return days;
    };
    //Função para limpar todos os inputs do formulário
    const clearForms = () => {
        setInputs({
            data: currentDate,
            nome: '',
            valor: 0,
            categoria: null,
            recorrente: false,
            fimRecorrencia: null,
            observacoes: '',
            dataFimFinanca: null,
            pagaOuRecebida: false,
            specific: false
        })
        setAlertUpdateFinance(false);
        setDetailFinances(false);
        setActiveRecurrenceSelectedFinance(false);
        setNumbersToEndRecurrence(0);
        setCreateNewGroup('');
        setOpenFormsCreateNewFinance(false);
        setClickedFinance(null)
        setSelectedCreditCard(null)
        setSelectedCategorie(null)
    }

    //Função para selecionar qual finança foi clicada, o card se expandir e ele se tornar o primeiro da posição
    const handleClickedFinance = (finance) => {
        const financeToMove = financeData.content.find((fin) => fin.id === finance.id);
        let updatedContent = [
            financeToMove,
            ...financialData.content.filter((fin) => fin.id !== finance.id)
        ];

        updatedContent = {
            ...financialData,
            content: updatedContent
        };

        setFinancialData(updatedContent);
        setClickedFinance(finance);

        updatedContent = [
            financeToMove,
            ...financeData.content.filter((fin) => fin.id !== finance.id)
        ];

        updatedContent = {
            ...financeData,
            content: updatedContent
        };

        if (type === 'income') {
            setIncomeData(updatedContent)
        } else {
            setSpendData(updatedContent)
        }

    }
    const calculateNumberOfFinances = (data) => {
        let totalValue = 0

        data.content.forEach((element) => {
            if (renderFinanceHandler(element)) {
                const finance = renderFinanceHandler(element)
                if (finance) {
                    totalValue += finance.valor;
                }
            }
        })
        totalValue = totalValue.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        setFinanceTotal(totalValue);
    }


    const headerView = () => {
        const categoriesSelect = categoriesData.filter(categorie => categorie.tipoFinanca === type);
        return (
            <HeaderOpenCardView
                financeTotal={financeTotal}
                type={type}
                setCloseFilterWindows={setCloseFilterWindows}
                closeFilterWindows={closeFilterWindows}
                categoriesRawData={categoriesSelect}
                setFinancialData={setFinancialData}
                financialRawData={financeData}
                renderFinanceHandler={renderFinanceHandler}
                setClickedFinance={setClickedFinance}
                clickedFinance={clickedFinance}
                creditCardRawData={creditCardData}
                monthName={monthName}
                closeFullView={closeFullView}
            />
        )
    }
    const renderRightForms = () => {
        /*Lógica e handlers*/
        let buttonText;
        let title;
        if (type === 'income' && clickedFinance) {
            buttonText = "Alterar receita";
            title = 'Editar receita';
        } else if (type === 'spend' && clickedFinance) {
            buttonText = "Alterar gasto";
            title = 'Editar gasto';
        } else if (type === 'income' && !clickedFinance) {
            buttonText = "Criar receita";
            title = 'Criar nova receita';
        } else if (type === 'spend' && !clickedFinance) {
            buttonText = "Criar gasto";
            title = 'Criar novo gasto';
        }
        const handleClickCreateFinance = (e) => {
            if (clickedFinance === null) {
                fetchNewFinance();
            } else if (!clickedFinance.recorrente || !inputs.recorrente) {
                fetchUpdateFinance(null);
            } else {
                e.stopPropagation();
                setAlertUpdateFinance(!alertUpdateFinance);
                setDeleteFinanceWindow(false);
            }
        }
        const handleClickMonthInput = () => {
            const dateInput = document.getElementById('day-input-click');
            dateInput.focus();
        };
        const updateValuePortion = () => {
            if (!numbersToEndRecurrence) return;
            const valorFloat = (parseFloat(inputs.valor.replace(/\./g, '').replace(',', '.')) / numbersToEndRecurrence).toFixed(2);
            handleInputValue(valorFloat.toString());
        }
        const dateEndRecurrence = clickedFinance && (inputs.fimRecorrencia || clickedFinance.fimRecorrencia);




        /*Header*/
        const handleActiveImportFinances = (e) => {
            handleCloseAllWindows(e);
            setDetailFinances(false);
            setClickedFinance(null);
            e.stopPropagation();
            setImportOptions(false)
            setActivateImportFinances(true)
        }
        const renderHeaderForms = (
            <header className='headerFormsCreateNewFinance'>
                <div>
                    {openFormsCreateNewFinance && <img onClick={clearForms} src='/Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/YellowArrow.svg' />}
                    <p>{title}</p>
                    <div />
                </div>
                <div>
                    <img src='Images_Dashboard_Summary/DefaultIcons/importFinancesIcon.svg'
                        alt='Importar finanças' onClick={handleActiveImportFinances} />
                </div>
            </header>
        )




        /*Body*/
        const inputNameField = (
            <div id='InputNameFinance' className='marginTop5'>
                <label>Nome</label>
                <input type='text' onChange={handleInputName} value={inputs.nome} autoFocus maxLength={95} />
            </div>
        )
        const inputValueField = (
            <div id='InputValueFinance'>
                <label>Valor</label>
                <input type='text' onChange={(e) => handleInputValue(e.target.value)} value={`R$ ${inputs.valor}`} maxLength={14} inputMode="numeric" />
            </div>
        )
        const inputDateField = (
            <div id='InputDateFinance'>
                <label >Dia</label>
                <input id='day-input-click' type='text' onChange={handleInputDate} value={inputs.data.toString().slice(-2)} inputMode="numeric" />
                <p onClick={handleClickMonthInput}>/{monthName}</p>
            </div>
        )
        const detailFinancesButton = (
            <div className='detailFinancesContainer'>
                <p p onClick={() => setDetailFinances(!detailFinances)}
                    className='buttonDetailFinance' >
                    {detailFinances ? 'Ocultar' : 'Detalhar informações'}
                    < img src='Images_Dashboard_Summary/SummaryView/OpenFinancialCard/grayArrow.svg'
                        style={{
                            transform: detailFinances ? 'rotate(180deg)' : null,
                            transition: 'transform 0.3s ease'
                        }}
                    />
                </p >
            </div>
        )
        const switchRecurrence = (
            <section>
                <span>Repetir nos próximos meses</span>


                {isMobile ?
                    <ToggleSliderSwitch
                        onChange={handleSwitchRecurrence}
                        active={activeRecurrenceSelectedFinance}
                        width={"12vw"}
                        height={"6vw"}
                        circle={"4.5vw"}
                        bottom={"0.75vw"}
                        leftOff={"0.4vw"}
                        leftOn={"1.0vw"}
                        cursor={"pointer"}
                        borderRadius={'3vw'}
                        translate={'6vw'}
                    />
                    :
                    <ToggleSliderSwitch
                        onChange={handleSwitchRecurrence}
                        active={activeRecurrenceSelectedFinance}
                        width={"2.7vw"}
                        height={"1.3vw"}
                        circle={"1vw"}
                        bottom={"0.12vw"}
                        leftOff={"0.2vw"}
                        leftOn={"0.1vw"}
                        cursor={"pointer"}
                        borderRadius={'1vw'}
                        translate={'1.3vw'}
                    />
                }
            </section>
        )
        const inputObservationField = (
            <div id='InputObservationFinance' className='marginTop5'>
                <label>Observações</label>
                <textarea
                    type='text'
                    id='ObservationsInputFinance'
                    rows="2"
                    cols="50"
                    style={{
                        resize: 'none',
                        width: '98%',
                        height: '3vw'
                    }}
                    maxLength={250}
                    onChange={handleInputObservations}
                    value={inputs.observacoes}
                />
            </div>
        )
        const renderCategorieSelectForms = () => {
            const userCategoriesData =
            {
                title: "Selecionar categoria",
                defaultDisplay: (
                    <div>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/AllCategoriesFilterIcon.svg' style={isMobile ? mobileStyleDefaultDisplayImg : {}} />
                        <p style={isMobile ? mobileStyleDefaultDisplayP : {}}>Selecionar categoria</p>
                    </div>
                ),
                values: [
                    {
                        name: (
                            <div
                                style={{
                                    backgroundColor: "#ec1c2414",
                                    paddingLeft: '0'
                                }}
                            >
                                <p
                                    style={{
                                        ...(isMobile ? mobileStyleDefaultDisplayP : {}),
                                        marginLeft: '0%'
                                    }}
                                >Sem categoria</p>
                            </div>
                        ),
                        id: 0
                    }
                ]
            }

            const mappedCategories = categoriesData.map(categorie => {
                if (categorie.tipoFinanca !== type) return;

                const icon = getCategorieIcon(categorie.nomeCategoria);

                let line;
                if (icon) {

                    line = (
                        <div>
                            <div
                                style={{
                                    ...(isMobile ? mobileStyleCategoriesList : {}),
                                    backgroundColor: categorie.categoriaCor,
                                }}
                            >
                                <img src={icon} alt="Categorie icon" style={isMobile ? mobileStyleCategoriesIconList : {}} />
                            </div>
                            <p style={isMobile ? {
                                ...mobileStyleDefaultDisplayP,
                                marginLeft: '3%'
                            } : {}}>{categorie.nomeCategoria}</p>
                        </div>
                    );
                } else {
                    line = (
                        <div>
                            <p style={isMobile ? mobileStyleDefaultDisplayP : {}}>{line = categorie.nomeCategoria}</p>
                        </div>
                    )
                }

                return {
                    name: line,
                    id: categorie.id,
                    hasImg: icon && true
                };
            }).filter(cat => cat !== undefined);

            userCategoriesData.values = userCategoriesData.values.concat(mappedCategories);

            userCategoriesData.values.sort((a, b) => {
                if (a.id === 0) return -1;  // Mantém o sem categoria na posição 0 
                if (b.id === 0) return 1;
                if (a.hasImg === true && b.hasImg !== true) {
                    return -1;
                }
                if (a.hasImg !== true && b.hasImg === true) {
                    return 1;
                }
                return 0;
            });

            const handleClickSelectCategorie = (categoria) => {
                if (categoria.id === 0) {
                    setSelectedCategorie(null);
                    setInputs((prevInputs) => ({
                        ...prevInputs,
                        categoria: null
                    }));
                    return;
                }
                setSelectedCategorie(categoria);
                setInputs((prevInputs) => ({
                    ...prevInputs,
                    categoria: categoria
                }));
            }

            return (
                <div>
                    <label>Vincular Categoria</label>
                    <Dropbox
                        data={userCategoriesData}
                        handleClick={handleClickSelectCategorie}
                        selectedValue={selectedCategorie}
                        closeFilterWindows={closeFilterWindows}
                        setCloseFilterWindows={setCloseFilterWindows}
                        textAlign={'left'}
                    />
                </div>
            )
        }
        const renderSelectCreditCard = () => {

            const userCreditCardData =
            {
                title: 'Selecionar cartão',
                defaultDisplay: (
                    <div>
                        <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/CreditCardFilterIcon.svg' style={isMobile ? mobileStyleDefaultDisplayImg : {}} />
                        <p style={isMobile ? mobileStyleDefaultDisplayP : {}} >Selecionar cartão</p>
                    </div>
                ),
                values: [
                    {
                        name: (
                            <div
                                style={{
                                    backgroundColor: "#ec1c2414",
                                    paddingLeft: '0'
                                }}
                            >
                                <p
                                    style={{
                                        marginLeft: '0',
                                        fontSize: isMobile && '3.8vw'
                                    }}
                                >Sem cartão</p>
                            </div>
                        ),
                        id: 0
                    }
                ]
            }

            const mappedCreditCards = creditCardData.content.map(credit => {
                const logo = getBankLogo(credit.banco_cartao);

                let line;
                if (logo) {
                    line = (
                        <div>
                            <img src={logo} alt="Bank Logo" style={isMobile ? mobileStyleCreditCardList : {}} />
                            <p style={{ fontSize: isMobile && '3.8vw' }}>{credit.nome_do_cartao}</p>
                        </div>
                    );
                }
                return {
                    name: line,
                    id: credit.id,
                    banco_cartao: credit.banco_cartao
                };
            });

            userCreditCardData.values = userCreditCardData.values.concat(mappedCreditCards);

            const handleClickSelectCreditCard = (creditCard) => {
                if (creditCard.id === 0) {
                    setSelectedCreditCard(null);
                    setInputs((prevInputs) => ({
                        ...prevInputs,
                        cartaoCredito: null
                    }));
                    return;
                }
                setSelectedCreditCard(creditCard);
                setInputs((prevInputs) => ({
                    ...prevInputs,
                    cartaoCredito: creditCard.id
                }));
            }

            return (
                <div>
                    <label>Vincular cartão de crédito</label>
                    <Dropbox
                        data={userCreditCardData}
                        handleClick={handleClickSelectCreditCard}
                        selectedValue={selectedCreditCard}
                        closeFilterWindows={closeFilterWindows}
                        setCloseFilterWindows={setCloseFilterWindows}
                        textAlign={'left'}
                    />
                </div>
            )
        }
        const selecaoDeParcelas = (
            <>
                <div id='InputRecurrenceFinance' className='marginTop5'>
                    {switchRecurrence}

                    {inputs.recorrente && (
                        <div className='setLimitDeactivateRecurrenceContainer'>
                            <div className='InputEndRecurrenceFinance'>
                                <button className='buttonRecurrenceDetails' onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setCalendarEndRecurrence(!calendarEndRecurrence);
                                    setDeleteFinanceWindow(false);
                                }}>
                                    <p>{
                                        inputs.fimRecorrencia
                                            ? ` Parcelas até ${format(parseISO(inputs.fimRecorrencia), 'yyyy/MM')}`
                                            : 'Número de parcelas'}
                                    </p>
                                    <img src='Images_Dashboard_Summary/SummaryView/OpenFinancialCard/blackCalendar.svg' alt='Calendário' />
                                </button>

                                {calendarEndRecurrence && (
                                    <div className='windowSetEndRecurrence' onClick={stopPropagation}>
                                        <input
                                            type='text'
                                            id='inputEndRecurrenceNumberOfMonths'
                                            maxLength={2}
                                            onChange={(e) => handleInputEndRecurrence(e.target.value)}
                                            value={numbersToEndRecurrence}
                                            inputMode="numeric" 
                                        />
                                        <p>O valor de {`R$ ${inputs.valor}`} é:</p>
                                        <div>
                                            <button onClick={(e) => {
                                                stopPropagation(e);
                                                setCalendarEndRecurrence(false);
                                            }}>Da parcela</button>
                                            <button onClick={(e) => {
                                                stopPropagation(e);
                                                setCalendarEndRecurrence(false);
                                                updateValuePortion();
                                            }}>Do total</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {(clickedFinance?.recorrente && !inputs.recorrente) && ( //caso a finança seja recorrente e o usuário esteja desativando
                        <p>Desativando a recorrência, a finança existirá apenas no mês de criação.</p>
                    )}


                </div>
                {inputObservationField}
                <div id='InputDropBoxFinance' className={type === 'income' ? 'creditCardPaddingSmartphone' : 'marginTop5'}>
                    {renderCategorieSelectForms()}
                </div>
                {type === 'spend' && (
                    <div id='InputDropBoxFinance' className={type === 'spend' ? 'creditCardPaddingSmartphone' : 'marginTop5'}>
                        {renderSelectCreditCard()}
                    </div>
                )}
            </>
        )
        const bodyForms = (
            <div className='formsCreateEditFinance'>
                {inputNameField}
                <div className='ValueAndDateFormsFinance marginTop5'>
                    {inputValueField}
                    {inputDateField}
                </div>
                {detailFinancesButton}
                {detailFinances && selecaoDeParcelas}
            </div >
        )






        const displayRuleUpdate = alertUpdateFinance ? 'flex' : 'none';
        const displayRuleDelete = deleteFinanceWindow ? 'flex' : 'none';

        const valuesUpdate = clickedFinance && [
            ((
                (inputs.fimRecorrencia && clickedFinance.fimRecorrencia && inputs.fimRecorrencia === clickedFinance.fimRecorrencia)
                || (!inputs.fimRecorrencia && !clickedFinance.fimRecorrencia)
            ) && (
                    <div
                        style={{
                            display: displayRuleUpdate,
                            borderColor: '#4CAA45',
                            height: '20%'
                        }}
                        onClick={() => fetchCreateSpecificFinance(true)}
                    >
                        <p style={{ color: '#4CAA45' }}>Alterar apenas este mês</p>

                        <div style={{ backgroundColor: '#4CAA45' }}>
                            <img src='Images_Dashboard_Summary/DefaultIcons/EditSpecific.svg'
                                style={{
                                    width: '60%'
                                }}
                            />
                        </div>
                    </div>
                )),
            ((!dateEndRecurrence) && (
                <div
                    style={{
                        display: displayRuleUpdate,
                        borderColor: '#2A6D24',
                        height: '20%'
                    }}
                    onClick={fetchUpdateNowOnFinance}
                >
                    <p style={{ color: '#2A6D24' }}>Alterar deste mês para frente</p>

                    <div style={{ backgroundColor: '#2A6D24' }}>
                        <img src='Images_Dashboard_Summary/DefaultIcons/EditNowOn.svg'
                            style={{
                                width: '60%'
                            }}
                        />
                    </div>
                </div>
            )),
            (
                <div
                    style={{
                        display: displayRuleUpdate,
                        borderColor: '#34CF27',
                        height: '20%'
                    }}
                    onClick={fetchUpdateFinance}
                >
                    <p style={{ color: '#34CF27' }}>Alterar todos os meses</p>

                    <div style={{ backgroundColor: '#34CF27' }}>
                        <img src='Images_Dashboard_Summary/DefaultIcons/EditAllMonths.svg'
                            style={{
                                width: '60%'
                            }}
                        />
                    </div>
                </div>
            )
        ];

        const valuesDelete = clickedFinance && [
            (
                <div
                    style={{
                        display: displayRuleDelete,
                        borderColor: '#CA2C2C',
                        height: '20%'
                    }}
                    onClick={() => fetchCreateSpecificFinance(false)}
                >
                    <p style={{ color: '#CA2C2C' }}>Excluir apenas este mês</p>

                    <div style={{ backgroundColor: '#CA2C2C' }}>
                        <img src='Images_Dashboard_Summary/DefaultIcons/EditSpecific.svg'
                            style={{
                                width: '60%'
                            }}
                        />
                    </div>
                </div>
            ),
            (!dateEndRecurrence && (
                <div
                    style={{
                        display: displayRuleDelete,
                        borderColor: '#932626',
                        height: '20%'
                    }}
                    onClick={() => {
                        const financeDate = new Date(`${yearSelected}-${adicionarZeroEsquerdaMes(monthNumber)}-01`)
                        fetchUpdateFinance(financeDate);
                    }}
                >
                    <p style={{ color: '#932626' }}>Excluir deste mês para frente</p>

                    <div style={{ backgroundColor: '#932626' }}>
                        <img src='Images_Dashboard_Summary/DefaultIcons/EditNowOn.svg'
                            style={{
                                width: '60%'
                            }}
                        />
                    </div>
                </div>
            )),
            (
                <div
                    style={{
                        display: displayRuleDelete,
                        borderColor: '#B13C3C',
                        height: '20%'
                    }}
                    onClick={fetchDeleteFinance}
                >
                    <p style={{ color: '#B13C3C' }}>Excluir todos os meses</p>

                    <div style={{ backgroundColor: '#B13C3C' }}>
                        <img src='Images_Dashboard_Summary/DefaultIcons/EditAllMonths.svg'
                            style={{
                                width: '60%'
                            }}
                        />
                    </div>
                </div>
            )
        ];




        const handleClickDeleteFinance = (e) => {
            if (!clickedFinance.recorrente) {
                fetchDeleteFinance();
            } else {
                e.stopPropagation()
                setDeleteFinanceWindow(!deleteFinanceWindow);
                setAlertUpdateFinance(false)
            }
        }
        /*Footer*/
        const updateCreateButton = (
            <div className='buttonUpdateFinanceContainer'>
                <button id='CreateFinanceButton' className='FormsFinanceViewButton' onClick={handleClickCreateFinance}>
                    {buttonText}
                </button>
                {(alertUpdateFinance && clickedFinance && inputs.recorrente && !isMobile) && (
                    <div className='checkTypeOfUpdateFinance'>
                        <ul>

                            {((inputs.fimRecorrencia && clickedFinance.fimRecorrencia && inputs.fimRecorrencia === clickedFinance.fimRecorrencia)
                                || (!inputs.fimRecorrencia && !clickedFinance.fimRecorrencia)
                            ) && (
                                    <li onClick={() => fetchCreateSpecificFinance(true)}>Apenas este mês</li>
                                )}

                            {!dateEndRecurrence && (
                                <li onClick={fetchUpdateNowOnFinance}>Deste mês para frente</li>
                            )}

                            <li onClick={() => fetchUpdateFinance(null)}>Todos os meses</li>

                        </ul>
                    </div>
                )}

                {isMobile && (
                    <OverlaySelection
                        overlayOn={alertUpdateFinance}
                        setOverlayOn={setAlertUpdateFinance}
                        values={valuesUpdate}
                        maxHeight={'30vh'}
                    />
                )}
            </div>
        )
        const deleteFinanceButton = (
            <div className='buttonUpdateFinanceContainer'>
                <button
                    id='DeleteFinanceButton'
                    className='FormsFinanceViewButton'
                    onClick={handleClickDeleteFinance}>
                    Excluir
                </button>
                {(deleteFinanceWindow && !isMobile) && (
                    <div onClick={stopPropagation} className='checkTypeOfDeleteFinance'>
                        <ul>
                            <li onClick={() => fetchCreateSpecificFinance(false)}>Apenas este mês</li>
                            {!dateEndRecurrence && (
                                <li onClick={() => {
                                    const financeDate = new Date(`${yearSelected}-${adicionarZeroEsquerdaMes(monthNumber)}-01`)
                                    fetchUpdateFinance(financeDate);
                                }}>Deste mês para frente</li>
                            )}
                            <li onClick={fetchDeleteFinance}>Todos os meses</li>
                        </ul>
                    </div>
                )}

                {isMobile && (
                    <OverlaySelection
                        overlayOn={deleteFinanceWindow}
                        setOverlayOn={setDeleteFinanceWindow}
                        values={valuesDelete}
                        maxHeight={'30vh'}
                    />
                )}
            </div>
        )
        const renderFooterForms = (
            <footer>
                <div
                    style={{
                        justifyContent: clickedFinance !== null ? "space-between" : "flex-end",
                        height: 'fit-content'
                    }}>
                    {clickedFinance !== null && deleteFinanceButton}
                    {updateCreateButton}
                </div>
            </footer>
        )

        return (
            <>
                {isMobile ? (
                    <section className={`overflowFormsCreateUpdateFinanceSmartphone ${openFormsCreateNewFinance ? "active" : ""}`}>
                        <div>
                            {renderHeaderForms}
                            <form>
                                {bodyForms}
                            </form>
                        </div>
                        {renderFooterForms}
                    </section>
                ) : (
                    <aside className='RightSideFilterAndForms'>
                        {renderHeaderForms}
                        <form>
                            {bodyForms}
                        </form>
                        {renderFooterForms}
                    </aside>
                )}
            </>
        )
    }




    const footerSmartphone = () => (
        <footer className='FooterSmartPhoneOpenFinances'>
            {!openFormsCreateNewFinance && (
                (() => {
                    const buttonText = clickedFinance && type === 'income' ? "Alterar receita" : !clickedFinance && type === 'income' ? "Nova receita" :
                        clickedFinance && type === 'spend' ? "Alterar gasto" : !clickedFinance && type === 'spend' ? "Novo gasto" : null;
                    return (
                        <button className='FormsFinanceViewButton' onClick={() => setOpenFormsCreateNewFinance(true)}
                            style={{
                                backgroundColor: type === 'income' ? '#489946' : '#ED4C5C'
                            }}
                        >{buttonText}</button>
                    );
                })()
            )}
        </footer>
    )
    const footerDestop = () => (
        <footer className='FooterDesktopOpenFinances'
            style={{
                backgroundColor: type === 'income' ? '#489946B2' : '#ED4C5CB2'
            }}
        >
            <div
                style={{
                    backgroundColor: type === 'income' ? '#489946' : '#ED4C5C'
                }}
            >
                <img src='/Images_Dashboard_Summary/DefaultIcons/SifraoBrancoIcon.svg' />
            </div>
            <p>R$ {financeTotal}</p>
        </footer>
    )


    //Hook para alterar o formulário para receber as informações da finança clicada e colocar elas em todos os inputs do formulário.
    useEffect(() => {
        if (clickedFinance !== null) {
            setDetailFinances(true);
            setActiveRecurrenceSelectedFinance(clickedFinance.recorrente);
            setInputs({
                data: clickedFinance.data,
                nome: clickedFinance.nome,
                valor: clickedFinance.valor.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
                categoria: clickedFinance.categoria ? clickedFinance.categoria : null,
                recorrente: clickedFinance.recorrente ? clickedFinance.recorrente : false,
                fimRecorrencia: clickedFinance.fimRecorrencia ? clickedFinance.fimRecorrencia : null,
                observacoes: clickedFinance.observacoes ? clickedFinance.observacoes : '',
                dataFimFinanca: clickedFinance.dataFimFinanca,
                pagaOuRecebida: clickedFinance.pagaOuRecebida,
                specificFinances: clickedFinance.specificFinances,
                specific: clickedFinance.specific,
                cartaoCredito: clickedFinance.creditCard ? clickedFinance.creditCard : null
            })
            if (clickedFinance.fimRecorrencia !== null) {
                const financeDate = parseISO(clickedFinance.data);
                const endRecurrence = parseISO(clickedFinance.fimRecorrencia)

                const monthsDifferenceTotal =
                    endRecurrence.getFullYear() === financeDate.getFullYear()
                        && endRecurrence.getMonth() === financeDate.getMonth() ? 1
                        : differenceInMonths(endRecurrence, financeDate) + 1;

                setNumbersToEndRecurrence(monthsDifferenceTotal);
            } else {
                setNumbersToEndRecurrence(0);
            }

            if (clickedFinance.creditCard) {
                const logo = getBankLogo(clickedFinance.creditCard.banco_cartao);
                const line = (
                    <div>
                        <img src={logo} alt="Bank Logo" style={isMobile ? mobileStyleCreditCardList : {}} />
                        <p style={isMobile ? mobileStyleDefaultDisplayP : {}}>{clickedFinance.creditCard.nome_do_cartao}</p>
                    </div>
                );

                const creditCard = {
                    name: line,
                    id: clickedFinance.creditCard.id,
                    banco_cartao: clickedFinance.creditCard.banco_cartao
                }
                setSelectedCreditCard(creditCard)
            } else {
                setSelectedCreditCard(null)
            }

            if (clickedFinance.categoria) {
                const logo = getCategorieIcon(clickedFinance.categoria.nomeCategoria);
                const line = (
                    <div>
                        {logo &&
                            <div
                                style={{
                                    ...(isMobile ? mobileStyleCategoriesList : {}),
                                    backgroundColor: clickedFinance.categoria.categoriaCor
                                }}
                            >
                                <img src={logo} alt="Categorie icon" style={{ display: 'block' }} />
                            </div>
                        }
                        <p style={isMobile ? mobileStyleDefaultDisplayP : {}}>{clickedFinance.categoria.nomeCategoria}</p>
                    </div>
                );

                const categorie = {
                    name: line,
                    id: clickedFinance.categoria.id
                }
                setSelectedCategorie(categorie)
            } else {
                setSelectedCategorie(null)
            }


        }
        else {
            clearForms();
        }
    }, [clickedFinance]);

    useEffect(() => {
        setDaysOfMonth(getDaysOfMonth());
    }, [])

    useEffect(() => {
        calculateNumberOfFinances(financialData);
    }, [financialData])

    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTo(0, 0);
        }
    }, [clickedFinance]);

    useEffect(() => {
        if (createFinance) {
            setOpenFormsCreateNewFinance(true)
        }
    }, [createFinance])

    return (
        <div
            className='WindowFullSpendIncomeCardOverlay'
            onClick={handleCloseAllWindows}
        >
            <div className='MainContainerOpenFinanceCard'>
                {headerView()}

                <section
                    className='OpenFinancialCardsContainer'
                    ref={containerRef}
                >
                    {financialData &&
                        financialData.content.map((element) => {
                            const finance = renderFinanceHandler(element)
                            return finance && (
                                <FinanceCardOpenView
                                    key={finance.id}
                                    financeCard={finance}
                                    type={type}
                                    categoriesData={categoriesData}
                                    daysOfMonth={daysOfMonth}
                                    monthNumber={adicionarZeroEsquerdaMes(monthNumber)}
                                    yearSelected={yearSelected}
                                    handleClickedFinance={handleClickedFinance}
                                    clickedFinance={clickedFinance}
                                    setClickedFinance={setClickedFinance}
                                    handleCheckFinance={handleClickPaidReceived}
                                />
                            );
                        })
                    }
                </section>

                {isMobile ? footerSmartphone(financeTotal) : footerDestop(financeTotal)}
            </div>

            {renderRightForms()}

            <ErrorMessage
                message={error_message}
                messageSetter={setError_message}
            />
            {activateImportFinances && (
                <>
                    <ImportFinancesWindow
                        type={type}
                        setActivateImportFinances={setActivateImportFinances}
                    />
                    <div className='importFinancesWindowOpacityOverlay' />
                </>
            )}
        </div>
    );
}

export default FinanceOpenCard;

