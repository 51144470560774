import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios'; // Certifique-se de importar axios
import { startOfMonth, format, parseISO } from 'date-fns'; // Certifique-se de importar funções necessárias do date-fns
import getMonth from 'date-fns/getMonth';

const FinancialContext = createContext();

export const FinancialProvider = ({ children }) => {

    const axiosURL = "https://sunna.app:8443"

    const [creditCardView, setCreditCardView] = useState(false);

    const [totalIncome, setTotalIncome] = useState(0.0);
    const [totalSpend, setTotalSpend] = useState(0.0);
    const [totalValue, setTotalValue] = useState(0.0);
    const [totalIncomeReceived, setTotalIncomeReceived] = useState(0.0);
    const [totalSpendPaid, setTotalSpendPaid] = useState(0.0);
    const [totalIncomeReceivedLeft, setTotalIncomeReceivedLeft] = useState(0.0);
    const [totalSpendPaidLeft, setTotalSpendPaidLeft] = useState(0.0);

    const [incomeData, setIncomeData] = useState(null);
    const [spendData, setSpendData] = useState(null);
    const [categoriesData, setCategoriesData] = useState(null);
    const [creditCardData, setCreditCardData] = useState(null);

    const [userFirstName, setUserFirstName] = useState("");
    const [userProfileImage, setUserProfileImage] = useState(null);
    const [hideFinances, setHideFinances] = useState(false);
    const [spendCategoriesData, setSpendCategoriesData] = useState(null);
    const [incomeCategoriesData, setIncomeCategoriesData] = useState(null);
    const [incomeDataReducedCard, setIncomeDataReducedCard] = useState(null);
    const [spendDataReducedCard, setSpendDataReducedCard] = useState(null);

    const [createNewGroup, setCreateNewGroup] = useState(false);

    const currentMonthIndex = getMonth(new Date());
    const monthNames = ["Janeiro-1", "Fevereiro-2", "Março-3", "Abril-4", "Maio-5", "Junho-6", "Julho-7", "Agosto-8", "Setembro-9", "Outubro-10", "Novembro-11", "Dezembro-12"];
    const [monthNumber, setMonthNumber] = useState(monthNames[currentMonthIndex].split("-")[1]);
    const [yearSelected, setYearSelected] = useState(new Date().getFullYear());
    const [monthName, setMonthName] = useState('');

    const renderFinanceHandler = (element, dateToCompare) => {
        if (element.recorrente && element.specificFinances && element.specificFinances.length > 0) {

            let specificFinance = null;
            const selectedMonth = dateToCompare ? dateToCompare : startOfMonth(new Date(parseInt(yearSelected), parseInt(monthNumber) - 1, 1));

            if (selectedMonth >= parseISO(element.dataFimFinanca)) return false

            specificFinance = element.specificFinances.find(item => format(parseISO(item.mesReferencia), 'yyyy-MM') === format(selectedMonth, 'yyyy-MM')
            );

            if (specificFinance && !specificFinance.ativaNoMes) return false;

            const returnSpecificFinance = (specificFinance) => ({
                id: specificFinance.idFinancaReferenciada,
                usuario_id: specificFinance.userId,
                nome: specificFinance.nome,
                data: specificFinance.mesReferencia,
                dataCriacao: element.data,
                valor: specificFinance.valor,
                recorrente: element.recorrente,
                fimRecorrencia: element.fimRecorrencia,
                categoria: specificFinance.categoria,
                observacoes: specificFinance.observacao,
                pagaOuRecebida: specificFinance.pagaOuRecebida,
                ativaNoMes: specificFinance.ativaNoMes,
                specific: specificFinance.specific,
                idSpecificFinance: specificFinance.id,
                repetirFinancaEspecifica: specificFinance.repetirFinanca,
                dataFinalEspecifica: specificFinance.dataFinalEspecifica,
                creditCard: specificFinance.creditCard
            });


            if (specificFinance && specificFinance.ativaNoMes && !specificFinance.repetirFinanca) {
                return returnSpecificFinance(specificFinance);
            }

            specificFinance = element.specificFinances.find(item =>
                item.repetirFinanca
                && !item.dataFinalEspecifica
                && item.ativaNoMes
                && selectedMonth >= startOfMonth(parseISO(item.mesReferencia))
            );

            if (specificFinance) {
                return returnSpecificFinance(specificFinance);
            }

            if (!specificFinance) {
                specificFinance = element.specificFinances.find(item => item.repetirFinanca
                    && item.dataFinalEspecifica
                    && item.ativaNoMes
                    && selectedMonth >= startOfMonth(parseISO(item.mesReferencia))
                    && selectedMonth < startOfMonth(parseISO(item.dataFinalEspecifica))
                );
            }

            if (specificFinance) {
                return returnSpecificFinance(specificFinance);
            }
        }

        const monthToCompare = dateToCompare ? format(dateToCompare, 'MM') : monthNumber
        const yearToCompare = dateToCompare ? parseInt(format(dateToCompare, 'yyyy'), 10) : yearSelected

        const naoRecorrenteEDoMesSelecionado =
            element.data
            && parseInt(element.data.split("-")[1]) === parseInt(monthToCompare)
            && parseInt(element.data.split("-")[0]) === parseInt(yearToCompare)
            && !element.recorrente;

        const recorrenteSemDataFimRecorrenciaESemFinalDaFinanca =
            element.data
            && element.recorrente
            && !element.fimRecorrencia
            && !element.dataFimFinanca
            && ((parseInt(monthToCompare) >= parseInt(element.data.split("-")[1]) && parseInt(element.data.split("-")[0]) === parseInt(yearToCompare))
                || parseInt(yearToCompare) > parseInt(element.data.split("-")[0]))

        const recorrenteSemDataFimRecorrenciaEComFinalDaFinanca =
            element.data
            && element.recorrente
            && !element.fimRecorrencia
            && element.dataFimFinanca
            && ((parseInt(monthToCompare) >= parseInt(element.data.split("-")[1]) && parseInt(element.data.split("-")[0]) === parseInt(yearToCompare))
                || parseInt(yearToCompare) > parseInt(element.data.split("-")[0]))
            && ((parseInt(monthToCompare) < parseInt(element.dataFimFinanca.split("-")[1]) && parseInt(element.dataFimFinanca.split("-")[0]) === parseInt(yearToCompare))
                || parseInt(yearToCompare) < parseInt(element.dataFimFinanca.split("-")[0]))


        const recorrenteComDataFimRecorrencia =
            element.data
            && element.recorrente
            && element.fimRecorrencia
            && ((parseInt(monthToCompare) >= parseInt(element.data.split("-")[1]) && parseInt(element.data.split("-")[0]) === parseInt(yearToCompare))
                || parseInt(yearToCompare) > parseInt(element.data.split("-")[0]))
            && ((parseInt(monthToCompare) <= parseInt(element.fimRecorrencia.split("-")[1]) && parseInt(element.fimRecorrencia.split("-")[0]) === parseInt(yearToCompare))
                || parseInt(yearToCompare) < parseInt(element.fimRecorrencia.split("-")[0]))

        if (naoRecorrenteEDoMesSelecionado || recorrenteSemDataFimRecorrenciaEComFinalDaFinanca || recorrenteSemDataFimRecorrenciaESemFinalDaFinanca || recorrenteComDataFimRecorrencia) {
            return element;
        }
        return false;
    }

    const updateDeleteCreateFinance = (action, finance, financeType) => {
        let updatedContent = {};
        let financesData = financeType === "income" ? incomeData : spendData;

        switch (action) {
            case "create": {
                updatedContent = {
                    ...financesData,
                    content: [
                        finance,
                        ...financesData.content
                    ]
                }
                break;
            }
            case "update": {
                updatedContent = {
                    ...financesData,
                    content: financesData.content.map((fin) => (fin.id === finance.id ? finance : fin))
                };
                break;
            }
            case "delete": {
                updatedContent = {
                    ...financesData,
                    content: financesData.content.filter((fin) => fin.id !== finance),
                };
                break;
            }
        }
        if (financeType === "income") {
            setIncomeData(updatedContent);
            setIncomeDataReducedCard(updatedContent);
        } else {
            setSpendData(updatedContent);
            setSpendDataReducedCard(updatedContent);
        }
    }

    //Função para manipular os objetos de finança específica
    const updateDeleteCreateSpecificFinance = (action, finance, financeType, idSpecificNowOn) => {
        let updatedContent = {};
        let financesData = financeType === "income" ? incomeData : spendData;

        switch (action) {
            case "create": {
                updatedContent = {
                    ...financesData,
                    content: financesData.content.map((fin) => {
                        if (fin.id !== finance.idFinancaReferenciada) {
                            return fin;
                        }
                        return {
                            ...fin,
                            specificFinances: fin.specificFinances.concat(finance)
                        };
                    }),
                };
                break;
            }
            case "update": {
                updatedContent = {
                    ...financesData,
                    content: financesData.content.map((fin) => {
                        if (fin.id !== finance.idFinancaReferenciada) {
                            return fin;
                        }
                        const filteredSpecificFinances = fin.specificFinances.filter((specific) => specific.id !== finance.id);
                        const updatedSpecificFinances = [...filteredSpecificFinances, finance];

                        return {
                            ...fin,
                            specificFinances: updatedSpecificFinances
                        };
                    }),
                };
                break;
            }
        }

        if (financeType === "income") {
            setIncomeData(updatedContent);
            setIncomeDataReducedCard(updatedContent);
        } else {
            setSpendData(updatedContent);
            setSpendDataReducedCard(updatedContent);
        }
    }

    //Função que atualiza o hook com as informações de categorias, seja criação, atualização ou deletar as informações dos hooks. 
    const updateDeleteCreateGroups = (action, financialGroup, type) => {
        let updatedCategoriesData = {};

        switch (action) {
            case "create": {
                updatedCategoriesData = [
                    ...categoriesData,
                    financialGroup
                ];
                break;
            }
            case "update": {
                updatedCategoriesData = categoriesData.map((group) => {
                    return group.id !== financialGroup.id ? group : financialGroup;
                }).filter((groupFiltered) => groupFiltered !== undefined)

                const financialUpdatedData = type === "receitas" ? incomeData : spendData;
                const updatedData = {
                    ...financialUpdatedData,
                    content: financialUpdatedData.content.map((finance) => {
                        if (((finance.categoria && finance.categoria.id !== financialGroup.id)
                            || !finance.categoria) && finance.specificFinances.length === 0) {
                            return finance;
                        }
                        if (finance.categoria && finance.categoria.id === financialGroup.id && finance.specificFinances.length === 0) {
                            return {
                                ...finance, categoria: financialGroup
                            }
                        }
                        return {
                            ...finance,
                            specificFinances: finance.specificFinances.map(sfin => {
                                if (!sfin.categoria || sfin.categoria.id !== financialGroup.id) {
                                    return sfin;
                                }
                                return {
                                    ...sfin,
                                    categoria: financialGroup
                                }
                            })
                        }
                    })
                };

                if (type === "receitas") setIncomeData(updatedData);
                else setSpendData(updatedData);
                break;
            }
            case "delete": {
                updatedCategoriesData = categoriesData.filter((group) => group.id !== financialGroup)

                const financialUpdatedData = type === "receitas" ? incomeData : spendData;

                const updatedData = {
                    ...financialUpdatedData,
                    content: financialUpdatedData.content.map((finance) => {

                        if ((finance.categoria && finance.categoria.id === financialGroup) && finance.specificFinances.length === 0) {
                            return {
                                ...finance,
                                categoria: null
                            }
                        }

                        if ((!finance.categoria || finance.categoria.id !== financialGroup) && finance.specificFinances.length === 0) {
                            return finance;
                        }

                        return {
                            ...finance,
                            specificFinances: finance.specificFinances.map(sfin => {

                                if (!sfin.categoria || sfin.categoria.id !== financialGroup) {
                                    return sfin;
                                }
                                return {
                                    ...sfin,
                                    categoria: null
                                }
                            })
                        }
                    })
                };

                if (type === "receitas") setIncomeData(updatedData);
                else setSpendData(updatedData);
                break;
            }
        }

        setCategoriesData(updatedCategoriesData);
        const incomeCategories = updatedCategoriesData.filter(categorie => categorie.tipoFinanca === 'income');
        const spendCategories = updatedCategoriesData.filter(categorie => categorie.tipoFinanca === 'spend');
        setIncomeCategoriesData(incomeCategories);
        setSpendCategoriesData(spendCategories);
    }

    const updateDeleteCreateCreditCard = (action, creditCard) => {
        let updatedCreditCardData = {};
        switch (action) {
            case "create": {
                updatedCreditCardData = {
                    ...creditCardData,
                    content: [
                        ...creditCardData.content,
                        creditCard
                    ]
                };
                break;
            }
            case "update": {
                updatedCreditCardData = {
                    ...creditCardData,
                    content: creditCardData.content.map((cred) => {
                        return cred.id !== creditCard.id ? cred : creditCard;
                    }).filter((credFilt) => credFilt !== undefined)
                };

                const updatedData = {
                    ...spendData,
                    content: spendData.content.map((finance) => {
                        if (((finance.creditCard && finance.creditCard.id !== creditCard.id)
                            || !finance.creditCard) && finance.specificFinances.length === 0) {
                            return finance;
                        }
                        if (finance.creditCard && finance.creditCard.id === creditCard.id && finance.specificFinances.length === 0) {
                            return {
                                ...finance, creditCard: creditCard
                            }
                        }
                        return {
                            ...finance,
                            specificFinances: finance.specificFinances.map(sfin => {
                                if (!sfin.creditCard || sfin.creditCard.id !== creditCard.id) {
                                    return sfin;
                                }
                                return {
                                    ...sfin,
                                    creditCard: creditCard
                                }
                            })
                        }
                    })
                };

                setSpendData(updatedData);
                break;
            }
            case "delete": {
                updatedCreditCardData = {
                    ...creditCardData,
                    content: creditCardData.content.filter((cred) => cred.id !== creditCard.id)
                };

                const updatedData = {
                    ...spendData,
                    content: spendData.content.map((finance) => {

                        if ((finance.creditCard && finance.creditCard.id === creditCard.id) && finance.specificFinances.length === 0) {
                            return {
                                ...finance,
                                creditCard: null
                            }
                        }

                        if ((!finance.creditCard || finance.creditCard.id !== creditCard.id) && finance.specificFinances.length === 0) {
                            return finance;
                        }

                        return {
                            ...finance,
                            specificFinances: finance.specificFinances.map(sfin => {
                                if (!sfin.creditCard || sfin.creditCard.id !== creditCard.id) {
                                    return sfin;
                                }
                                return {
                                    ...sfin,
                                    creditCard: null
                                }
                            })
                        }
                    })
                };

                setSpendData(updatedData);
                break;
            }
        }

        setCreditCardData(updatedCreditCardData);
    }

    const getTotalValue = () => {
        let income = 0;
        let spend = 0;
        let receivedIncome = 0;
        let paidSpend = 0;

        if (incomeData) {
            incomeData.content.forEach(element => {
                const finance = renderFinanceHandler(element);
                if (finance) {
                    income += finance.valor;
                    if (finance.pagaOuRecebida) {
                        receivedIncome += finance.valor;
                    }
                }
            });
        }

        if (spendData) {
            spendData.content.forEach(element => {
                const finance = renderFinanceHandler(element);
                if (finance) {
                    spend += finance.valor;
                    if (finance.pagaOuRecebida) {
                        paidSpend += finance.valor;
                    }
                }
            });
        }

        setTotalSpend(spend.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        setTotalIncome(income.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        setTotalValue((income - spend).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        setTotalSpendPaid(paidSpend.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        setTotalIncomeReceived(receivedIncome.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        setTotalIncomeReceivedLeft((income - receivedIncome).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
        setTotalSpendPaidLeft((spend - paidSpend).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));
    }

    const checkTokenValidity = async (fetch) => {
        try {
            const token = localStorage.getItem("SnnAcss");
            if (!token) throw new Error();
            const response = await axios.get(`${axiosURL}/login/confirm-login`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const newToken = response.data.token;
            localStorage.setItem("SnnAcss", newToken);
            if (fetch) fetchFinancialData(newToken);
            return true;
        } catch (e) {
            return false;
        }
    }

    const fetchFinancialData = async (token) => {
        try {
            const header = {
                Authorization: `Bearer ${token}`,
            }
            const [incomeResponse, spendResponse, categoriesResponse, userData, creditCardResponse] = await Promise.all([
                axios.get(`${axiosURL}/income`, { headers: header }),
                axios.get(`${axiosURL}/spend`, { headers: header }),
                axios.get(`${axiosURL}/financialGroup`, { headers: header }),
                axios.get(`${axiosURL}/users/getUserName`, { headers: header }),
                axios.get(`${axiosURL}/creditCard`, { headers: header })
            ]);

            if (incomeResponse.status === 200) {
                setIncomeData(incomeResponse.data);
                setIncomeDataReducedCard(incomeResponse.data);
            }

            if (spendResponse.status === 200) {
                setSpendData(spendResponse.data);
                setSpendDataReducedCard(spendResponse.data);
            }

            if (creditCardResponse.status === 200) {
                setCreditCardData(creditCardResponse.data);
            }

            if (categoriesResponse.status === 200) { //Colocando os grupos respectivos de receitas e gastos nos seus useState
                setCategoriesData(categoriesResponse.data.content);
                const contentArray = categoriesResponse.data.content || [];
                const incomeCategories = contentArray.filter(categorie => categorie.tipoFinanca === 'income');
                const spendCategories = contentArray.filter(categorie => categorie.tipoFinanca === 'spend');
                setIncomeCategoriesData(incomeCategories);
                setSpendCategoriesData(spendCategories);
            }

            if (userData.status === 200) {
                setUserFirstName(userData.data.userFirstName);
                setUserProfileImage(userData.data.userProfileImage);
            }

            if (incomeResponse.status === 200 && spendResponse.status === 200) {
                getTotalValue();
            }

        } catch (error) {
            console.log("Error fetching data:", error);
        }
    }

    const changeMonth = (month) => {
        setMonthNumber(month);
    };
    const changeYear = (year) => {
        setYearSelected(year)
    }
    const getMonthName = () => {
        const months = monthNames.map((month) => month.split("-")[1])
        const index = months.indexOf(monthNumber)
        setMonthName(monthNames[index].split("-")[0]);
    }



    useEffect(() => {
        getTotalValue();
        getMonthName();
    }, [monthNumber, yearSelected, incomeData, spendData]);

    return (
        <FinancialContext.Provider value={{
            totalIncome,
            totalSpend,
            totalValue,
            totalIncomeReceived,
            totalSpendPaid,
            totalIncomeReceivedLeft,
            totalSpendPaidLeft,
            incomeData,
            spendData,
            categoriesData,
            userFirstName,
            userProfileImage,
            monthNumber,
            setMonthNumber,
            yearSelected,
            setYearSelected,
            renderFinanceHandler,
            monthName,
            setHideFinances,
            hideFinances,
            changeMonth,
            changeYear,
            incomeCategoriesData,
            spendCategoriesData,
            incomeDataReducedCard,
            spendDataReducedCard,
            updateDeleteCreateGroups,
            updateDeleteCreateSpecificFinance,
            updateDeleteCreateFinance,
            checkTokenValidity,
            axiosURL,
            setCreateNewGroup,
            createNewGroup,
            setIncomeData,
            setSpendData,
            creditCardData,
            setUserProfileImage,
            updateDeleteCreateCreditCard,
            setCreditCardView,
            creditCardView
        }}>
            {children}
        </FinancialContext.Provider>
    );
};

export const useFinancialContext = () => useContext(FinancialContext);
